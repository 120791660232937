<template>
  <section 
    v-if="currentUser?.email === 'jonnie.hallman@gmail.com'"
    class="CommissionerView"
  >
    <CreateRunView />
    <InviteUserView />
  </section>
  <p v-else>Not signed in as Jonnie</p>
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';
import CreateRunView from '@/views/CreateRunView.vue';
import InviteUserView from '@/views/InviteUserView.vue';
import { storeToRefs } from 'pinia';

const {currentUser} = storeToRefs(useAuthStore())
</script>

<style lang="scss" scoped>
.CommissionerView {
  display: grid;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  height: 100%;
}
</style>