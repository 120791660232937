import { createRouter } from "@/lib/router/router";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";

import "./Colors.scss";
import "./Fonts.scss";
import "./Global.scss";
import "./Themes.scss";

createApp(App)
  .use(createPinia())
  .use(createRouter())
  .mount("body")
