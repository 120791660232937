<template>
  <form 
    @submit.prevent="handleCreateRun"
  >
    <fieldset :disabled="isRequesting">
      <p v-if="!currentUser">No current user</p>
      <h2>Create a run</h2>
      <label>
        Crews
        <select 
          v-model="form.crewIds"
          multiple
          required
        >
          <option
            v-for="crew in crews"
            :key="crew.id"
            :value="crew.id"
          >{{ crew.name }}</option>
        </select>
      </label>
      <label>
        Court
        <select 
          v-model="form.courtId"
          required
        >
          <option :value="null" disabled>Pick a court…</option>
          <option
            v-for="court in courts"
            :key="court.id"
            :value="court.id"
          >{{ court.name }}</option>
        </select>
      </label>
      <label>
        Start
        <input 
          type="datetime-local"
          v-model="form.startDatetime"
          required
        >
      </label>
      <label>
        Season
        <select 
          v-model="form.seasonId"
          required
        >
          <option :value="null">–</option>
          <option
            v-for="season in seasons"
            :key="season.id"
            :value="season.id"
          >{{ season.name }}</option>
        </select>
      </label>
      <label>
        Duration<br>
        <template v-if="form.duration">{{ form.duration }} hours</template>
        <input 
          type="range"
          v-model.number="form.duration"
          :min="0"
          :max="3"
          :step="0.5"
          required
        />
      </label>
      <pre>// Form

{{ form }}</pre>
      <pre>// Run params

{{ runParams }}</pre>
      <pre v-if="run">// Run

{{ run }}</pre>
      <button
        type="submit"
      >
        <progress v-if="isRequesting" /><template v-else>Submit</template>
      </button>
    </fieldset>
  </form>
</template>

<script lang="ts" setup>
import { Tables, TablesInsert } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import useAuthStore from '@/stores/useAuthStore';
import { TZDate } from '@date-fns/tz';
import { addHours, format, setHours, startOfDay } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

const {currentUser} = storeToRefs(useAuthStore())

const form = ref(getInitialForm())
const isRequesting = ref(false)
const crews = ref<Array<Tables<"crews">>>([])
const courts = ref<Array<Tables<"courts">>>([])
const seasons  = ref<Array<Tables<"seasons">>>([])
const run = ref<Tables<"runs">>()

supabaseClient.from("crews").select().order("name").then(({data: _crews}) => {
  crews.value = _crews || []
  form.value.crewIds = [crews.value[0].id]
})

supabaseClient.from("courts").select().then(({data: _courts}) => {
  courts.value = _courts || []
  form.value.courtId = _courts?.at(0)?.id || null
})

supabaseClient.from("seasons").select().then(({data: _seasons}) => {
  seasons.value = _seasons || []
})

const runParams = computed(():TablesInsert<"runs"> | void => {
  if (!currentUser.value) return;

  const court = courts.value.find((court) => court.id === form.value.courtId)
  if (!court) return;

  const startDate = new TZDate(form.value.startDatetime, court.timezone)
  const endDate = form.value.duration ? addHours(startDate, form.value.duration) : null

  return {
    court_id: form.value.courtId,
    season_id: form.value.seasonId,
    start_at: startDate.toISOString(),
    end_at: endDate?.toISOString(),
    user_id: currentUser.value?.id,
  }
})

const crewRunParams = computed(():Array<TablesInsert<"crew_runs">> | void => {
  if (!currentUser.value) return;

  return form.value.crewIds.map((crewId) => {
    return {
      crew_id: crewId,
      run_id: run.value?.id || "TBD",
    }
  })
})

function getInitialForm() {
  return {
    crewIds: <Array<string>>[],
    courtId: null as string | null,
    seasonId: null as string | null,
    startDatetime: format(setHours(startOfDay(new Date), 8), "yyyy-MM-dd'T'hh:mm"),
    duration: 1.5,
  }
}

async function handleCreateRun() {
  if (!runParams.value || !crewRunParams.value) return

  isRequesting.value = true
  const {data: _run, error} = await supabaseClient.from("runs").insert(runParams.value).select().maybeSingle()
  if (error) alert(error.message)
  if (_run) {
    run.value = _run

    const {error} = await supabaseClient.from("crew_runs").insert(crewRunParams.value)
    if (error) alert(error.message)
  }
  isRequesting.value = false
}
</script>

<style lang="scss" scoped>
form {
  padding: 16px;
  outline: solid 1px black;
}

h2 {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
}

fieldset {
  display: grid;
  gap: 16px;
}

input,
select {
  margin-top: 4px;
  padding: 8px;
  width: 100%;
}

button {
  padding: 8px;
  background-color: var(--themePrimary);
  color: white;
}

pre {
  padding: 8px;
  background-color: black;
  color: white;
  font-size: 12px;
  overflow-x: auto;
}
</style>