<template>
  <form 
    @submit.prevent="handleInviteUser"
  >
    <fieldset :disabled="isRequesting">
      <h2>Invite a player</h2>
      <label>
        Name
        <input 
          type="name"
          v-model="form.name"
          required
        >
      </label>
      <label>
        Email
        <input 
          type="email"
          v-model="form.email"
          required
        >
      </label>
      <button
        type="submit"
      >
        <progress v-if="isRequesting" /><template v-else>Submit</template>
      </button>
    </fieldset>
  </form>
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const {session} = storeToRefs(useAuthStore())

const form = ref(getInitialForm())
const isRequesting = ref(false)

function getInitialForm() {
  return {
    name: "",
    email: "",
  }
}

async function handleInviteUser() {
  isRequesting.value = true
  try {
    const response = await fetch("/api/users/invite", {
      method: "POST",
      body: JSON.stringify(form.value),
      headers: {
        authorization: session.value!.access_token
      }
    })

    if (response.ok) {
      form.value = getInitialForm()
    } else {
      const {error} = await response.json()
      alert(error)
    }
  } catch (err) {
    alert(JSON.stringify(err))
  }
  isRequesting.value = false
}
</script>

<style lang="scss" scoped>
form {
  padding: 16px;
  outline: solid 1px black;
}

h2 {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
}

fieldset {
  display: grid;
  gap: 16px;
}

input {
  margin-top: 4px;
  padding: 8px;
  width: 100%;
}

button {
  padding: 8px;
  background-color: var(--themePrimary);
  color: white;
}
</style>