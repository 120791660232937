<template>
  <section 
    class="ScheduleView"
    ref="scheduleViewRef"
  >
    <ListGroup
      v-for="monthGroup in groupedRunItems"
      :key="monthGroup.datetime"
      :label="monthGroup.label"
    >
      <TimelineRunGroup 
        v-for="group in monthGroup.groups"
        :key="group.datetime"
        :date="group.date"
      >
        <TimelineRunItem
          v-for="item in group.items"
          :key="item.run.id"
          :modelValue="item"
        />
      </TimelineRunGroup>
    </ListGroup>
  </section>
</template>

<script lang="ts" setup>
import ListGroup from '@/components/ListGroup.vue';
import { listHeaderHeight } from '@/components/ListHeader.types';
import TimelineRunGroup from '@/components/TimelineRunGroup.vue';
import { RunItem } from '@/components/TimelineRunItem.types';
import TimelineRunItem from '@/components/TimelineRunItem.vue';
import { supabaseClient } from '@/lib/database/supabase';
import isTruthy from '@/lib/utils/boolean/isTruthy';
import { QueryData } from '@supabase/supabase-js';
import { format, isFuture, isSameDay, isSameMonth, isThisYear, parseISO, startOfDay, startOfMonth } from 'date-fns';
import { computed, nextTick, onMounted, ref, useTemplateRef } from 'vue';

const today = startOfDay(new Date)
const scheduleViewRef = useTemplateRef("scheduleViewRef")

const runsWithCourtsQuery = supabaseClient.from("runs").select("*, court:courts(*)").order("start_at", {ascending: false})
type RunsWithCourts = QueryData<typeof runsWithCourtsQuery>
  
const crewRunsWithCrewsQuery = supabaseClient.from("crew_runs").select("*, crew:crews(*)")
type CrewRunsWithCrews = QueryData<typeof crewRunsWithCrewsQuery>

const runs = ref<RunsWithCourts>([])
const crewRuns = ref<CrewRunsWithCrews>([])

onMounted(async () => {
  const {data: _runs} = await runsWithCourtsQuery
  const {data: _crewRuns} = await crewRunsWithCrewsQuery.in("run_id", _runs?.map((run) => run.id) || [])

  if (_runs) runs.value = _runs
  if (_crewRuns) crewRuns.value = _crewRuns

  await nextTick()
  scrollToCurrentRunGroup()
})

const runItems = computed(():Array<RunItem> => {
  return runs.value?.map((run) => {
    const crews = crewRuns.value?.filter((crewRun) => crewRun.run_id === run.id).map((crewRun) => crewRun.crew).filter(isTruthy) || []

    return {
      run,
      court: run.court!,
      crews,
    }
  }) || []
})

const currentRunGroup = computed(() => {
  const runGroups = groupedRunItems.value.flatMap(({groups}) => groups)

  return runGroups.reverse().find(({date}) => isSameDay(date, today) || isFuture(date))
})

const groupedRunItems = computed(() => {
  return runItems.value.reduce<Array<{
    label: string, 
    date: Date, 
    datetime: string,
    groups: [
      {
        date: Date, 
        datetime: string,
        items: Array<RunItem>,
      }
    ]
  }>>((acc, runItem) => {
    const runDate = parseISO(runItem.run.start_at)
    const lastMonthGroup = acc.at(-1)

    if (!lastMonthGroup || !isSameMonth(runDate, lastMonthGroup.date)) {
      const groupDate = startOfDay(runDate)
      
      acc.push({
        label: format(runDate, isThisYear(runDate) ? "MMMM" : "MMMM yyyy"),
        date: startOfMonth(runDate),
        datetime: runItem.run.start_at,
        groups: [
          {
            date: groupDate,
            datetime: groupDate.toISOString(),
            items: [runItem]
          }
        ]
      })
    } else if (!isSameDay(runDate, lastMonthGroup.groups.at(-1)!.date)) {
      const groupDate = startOfDay(runDate)

      lastMonthGroup.groups.push({
        date: groupDate,
        datetime: groupDate.toISOString(),
        items: [runItem]
      })
    } else {
      lastMonthGroup.groups.at(-1)?.items.unshift(runItem)
    }

    return acc
  }, [])
})

function scrollToCurrentRunGroup () {
  if (currentRunGroup.value) {
    const runGroupEl = scheduleViewRef.value?.querySelector(`.TimelineRunGroup[data-datetime='${currentRunGroup.value.datetime}']`)
    if (!runGroupEl) return

    document.querySelector(".AppLayout__main")!.scrollTo({
      top: runGroupEl.getBoundingClientRect().top 
        - document.querySelector(".AppLayout__main")!.getBoundingClientRect().top 
        - listHeaderHeight
    })
  }
}
</script>

<style lang="scss" scoped>
.ScheduleView {
  padding-bottom: 100%;
}
</style>