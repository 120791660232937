<template>
  <article class="TimelineRunItem">
    <header class="TimelineRunItem__header">
      <h6 class="TimelineRunItem__crew"
      >{{ formattedCrewNames }}</h6>
      <h5 class="TimelineRunItem__time">
        <time :datetime="modelValue.run.start_at">{{ formattedStartTime }}</time><template v-if="modelValue.run.end_at && formattedEndTime">&thinsp;–&thinsp;<time :datetime="modelValue.run.end_at">{{ formattedEndTime }}</time></template>
      </h5>
      <h6 class="TimelineRunItem__court">
        <Icon
          class="TimelineRunItem__courtIcon"
          name="location"
        />
        <span class="TimelineRunItem__courtName">{{ modelValue.court.name }}</span>
      </h6>
    </header>
    <footer class="TimelineRunItem__footer">
      <ul class="TimelineRunItem__footerButtonList">
        <li class="TimelineRunItem__footerButtonItem">
          <button 
            class="TimelineRunItem__footerButton"
            @click="handleButtonClick"
          >
            <Icon 
              class="TimelineRunItem__footerButtonIcon"
              name="check" 
            /> 
            <span class="TimelineRunItem__footerButtonLabel">&nbsp;</span>
          </button>
        </li>
        <li class="TimelineRunItem__footerButtonItem">
          <button 
            class="TimelineRunItem__footerButton"
            @click="handleButtonClick"
          >
            <Icon 
              class="TimelineRunItem__footerButtonIcon"
              name="circleHalf" 
            />
            <span class="TimelineRunItem__footerButtonLabel">&nbsp;</span>
          </button>
        </li>
        <li class="TimelineRunItem__footerButtonItem">
          <button 
            class="TimelineRunItem__footerButton"
            @click="handleButtonClick"
          >
            <Icon 
              class="TimelineRunItem__footerButtonIcon"
              name="x" 
            />
            <span class="TimelineRunItem__footerButtonLabel">&nbsp;</span>
          </button>
        </li>
      </ul>
    </footer>
  </article>
</template>

<script lang="ts" setup>
import Icon from '@/components/Icon.vue';
import { RunItem } from '@/components/TimelineRunItem.types';
import { format, parseISO } from 'date-fns';
import { computed } from 'vue';

const modelValue = defineModel<RunItem>({required: true})

const formattedCrewNames = computed(() => {
  return modelValue.value.crews.map((crew) => crew.name).join(", ")
})

const startDate = computed(() => parseISO(modelValue.value.run.start_at))
const formattedStartTime = computed(() => formatDate(startDate.value))

const endDate = computed(() => modelValue.value.run.end_at ? parseISO(modelValue.value.run.end_at) : null)
const formattedEndTime = computed(() => formatDate(endDate.value))

function formatDate(date:Date | null):string | null {
  if (!date) return null

  return format(date, date.getMinutes() > 0 ? "h:mmaaa" : "haaa")
}

function handleButtonClick() {
  alert("Updating your status isn’t available yet!")
}
</script>

<style lang="scss" scoped>
.TimelineRunItem {
  display: grid;
  gap: 16px;
  padding: 19px 16px 15px;
  position: relative;
  outline: dashed 1px var(--themeNeutral100);
}

.TimelineRunItem__header {
  display: grid;
}

.TimelineRunItem__crew {
  font-size: 13px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TimelineRunItem__time {
  margin-top: 12px;
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
}

.TimelineRunItem__court {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.TimelineRunItem__courtIcon {
  color: var(--themeNeutral300)
}

.TimelineRunItem__courtName {
  position: relative;
  top: 1px;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TimelineRunItem__footerButtonList {
  display: grid;
  grid-auto-flow: column;
}

.TimelineRunItem__footerButton {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--fontFamilyMono);
  font-size: 15px;
  color: var(--themeNeutral500);
}

.TimelineRunItem__footerButtonIcon {
  color: var(--themeNeutral400);
}

.TimelineRunItem__footerButtonLabel {
  position: relative;
  top: 1px;
}
</style>
