import AppLayout from "@/layouts/AppLayout.vue";
import AcceptRunInviteView from "@/views/AcceptRunInviteView.vue";
import AccountView from "@/views/AccountView.vue";
import AuthView from "@/views/AuthView.vue";
import ClaimPlayerView from "@/views/ClaimPlayerView.vue";
import CommissionerView from "@/views/CommissionerView.vue";
import IndexView from "@/views/IndexView.vue";
import ScheduleView from "@/views/ScheduleView.vue";
import SpreadsheetView from "@/views/SpreadsheetView.vue";
import { RouteRecordRaw } from "vue-router";

export enum RouteName {
  account = "account",
  commish = "commish",
  claimPlayer = "claimPlayer",
  index = "index",
  login = "login",
  runInviteAccept = "runInviteAccept",
  spreadsheet = "spreadsheet",
  schedule = "schedule",
}

export const routes:Array<RouteRecordRaw> = [
  {
    name: RouteName.login,
    path: "/login",
    component: AuthView,
  },
  {
    name: RouteName.index,
    path: "/",
    component: IndexView,
  },
  {
    name: RouteName.account,
    path: "/account",
    component: AccountView,
  },
  {
    name: RouteName.commish,
    path: "/commish",
    component: CommissionerView,
  },
  {
    name: RouteName.spreadsheet,
    path: "/spreadsheet",
    component: SpreadsheetView,
  },
  {
    name: RouteName.runInviteAccept,
    path: "/run-invites/:runId/:playerId/accept",
    component: AcceptRunInviteView,
  },
  {
    name: RouteName.claimPlayer,
    path: "/players/:id/claim",
    component: ClaimPlayerView,
  },
  {
    path: "",
    component: AppLayout,
    children: [
      {
        name: RouteName.schedule,
        path: "/schedule",
        component: ScheduleView,
      },
    ]
  }
]