<template>
  <h1>Bball.app</h1>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()

onMounted(() => {
  if (route.query.error && route.query.error_description) {
    alert(route.query.error_description)
  }
})
</script>

<style lang="scss" scoped>
</style>